import React from "react";

const PostGrad = () => {
  return (
    <div className="WWDContainer text-white flex-col justify-center items-center md:flex-row">
      <div className="item-1 ">
        <h1
          style={{ color: "white" }}
          className=" p-5 text-xl sm:text-2xl md:text-3xl lg:text-5xl text-center md:text-left"
        >
          Have your <br /> post-grad <br /> CPA job already?
        </h1>
      </div>

      <div className="item-2">
        <h2 className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl">
          <hr className="line-break" />
          Congratulations! We know you will do great. We may be able to help you
          seize your next opportunity after you’ve honored your commitment and
          earned the right to advance. Add us on LinkedIn and stay in touch.
          We are a valuable asset in your professional network.
        </h2>
      </div>
    </div>
  );
};

export default PostGrad;
