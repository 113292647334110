import React from "react";
import "./Testimonials.scss";

const Testimonials = () => {
  return (
    <div className="TContainer bg-white text-white flex-col justify-center items-center md:flex-row">
      <div style={{ backgroundColor: "white" }} className="item-1">
        <h1
          style={{ color: "black" }}
          className="p-5 text-xl sm:text-2xl md:text-3xl lg:text-5xl text-center md:text-left"
        >
          Testimonials
        </h1>
        
        <img
          // className="w-32 h-32 rounded-full mx-auto md:mx-0 md:mr-6"
          src="assets/img/testimonial.png"
          alt="test"
        />
      </div>
      <div style={{ backgroundColor: "white" }} className="item-2">
        <h2
          style={{ justifyContent: "left", color: "black" }}
          className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl"
        >
          <hr className="line-break" />
          <i>
            "Launching my CPA career abroad was one of the best choices I ever
            made, both professionally and personally. I further developed my
            technical competencies while exploring different countries,
            different ways of life and, most importantly, learning more about
            myself."
          </i>
          <br />
          <br />
          <i>
            "Starting my CPA career internationally was definitely the right
            decision for me. I’ve now worked in three countries in three sectors
            over six years in public practice, consulting, and corporate
            finance. It’s expanded my network and opened up professional
            opportunities for me that I would have never otherwise thought
            imaginable."
          </i>
        </h2>
      </div>
    </div>
  );
};

export default Testimonials;
