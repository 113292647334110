import React from "react";
import "./WhatWeDo.scss";

const WhatWeDo = () => {
  return (
    <div className="WWDContainer text-white flex-col justify-center items-center md:flex-row ">
      <div className="item-1 text-center md:text-left">
        <h1 className=" p-5 text-2xl md:text-3xl lg:text-5xl">
          What we do
        </h1>
      </div>

      <div className="item-2">
        <h2 className="text-justify mx-12 text-lg sm:text-xl md:text-2xl lg:text-3xl">
          <hr className="line-break" />
          We specialize in presenting international career-launch opportunities
          to senior students and recent graduates from Canadian CPA-accredited
          university programs. We work mainly with candidates by invitation and
          referral.
        </h2>
      </div>
    </div>
  );
};

export default WhatWeDo;
