import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <p>
      Copyright Ⓒ Vin Mitchell. Ontario, Canada. All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
