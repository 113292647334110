import React, { useState } from "react";
import FormFooter from "./components/FormFooter";
import FormNavBar from "./components/FormNavBar";
import "./InquiryForm.scss";
import axios from "axios";
// import { sendMail } from "./sendEmail";
import { SuccessAlert, ErrorAlert } from "./components/alerts";


const InquiryForm = () => {
  const [inputs, setInputs] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const OnFormSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);
    try {

      const result = await axios.post(
        "/api/v1/inquire-form",
        inputs
      );
      if (result.data.success) {
        setSuccess(true);
        setMessage(result.data.message);
        //setInputs({});
      }
      else {
        setError(true);
        setMessage(result.data.message);
      }

    } catch (error) {
      setError(true);
      console.log(error);
      if (error.response.data.error){
      setMessage(error.response.data.error);
      }else{
        setMessage(error.message)
      }
    }
  };

  const handleChange = (e) => {
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="IQFormContainer">
      <FormNavBar />
      <div className="formContainer">
        {/* <form onSubmit={OnFormSubmit}> */}
          <form onSubmit={OnFormSubmit} style={{ marginBottom: "100px" }}>
          <div className="flex flex-col md:flex-row m-2">
            <label htmlFor="id_first_name">First Name:</label>
            <input
              type="text"
              name="first_name"
              maxLength="100"
              required
              id="id_first_name"
              value={inputs.first_name}
              onChange={handleChange}
              className="text-black"
            />
          </div>
          <div className="flex flex-col md:flex-row m-2">
            <label htmlFor="id_last_name">Last Name:</label>
            <input
              type="text"
              name="last_name"
              maxLength="100"
              required
              id="id_last_name"
              onChange={handleChange}
              className="text-black"
              value={inputs.last_name}
            />
          </div>
          <div className="flex flex-col md:flex-row m-2">
            <label htmlFor="id_email">Email Address:</label>
            <input
              type="email"
              name="email"
              maxLength="100"
              required
              id="id_email"
              onChange={handleChange}
              className="text-black"
              value={inputs.email}
            />
          </div>
          <div className="flex flex-col md:flex-row m-2">
            <label htmlFor="id_final_term">
                Month/Year of Final Undergraduate Degree Studies:
            </label>
            <input
              required
              type="text"
              name="final_term"
              maxLength="100"
              id="id_final_term"
              onChange={handleChange}
              className="text-black"
              value={inputs.final_term}
            />
          </div>
          <div className="flex flex-col md:flex-row m-2">
            <label htmlFor="id_linkedin_profile">LinkedIn Profile Link:</label>
            <input
              type="text"
              name="linkedin_profile"
              maxLength="200"
              id="id_linkedin_profile"
              onChange={handleChange}
              value={inputs.linkedin_profile}
              className="text-black"
            />
          </div>
          <div className="flex flex-col md:flex-row m-2">
            <label htmlFor="id_message">Message:</label>
            <textarea
              name="message"
              cols="40"
              rows="10"
              maxLength="1000"
              required
              id="id_message"
              onChange={handleChange}
              value={inputs.message}
              className="text-black"
            ></textarea>
          </div>
          <input type="submit" value="Submit" />
          {success && <SuccessAlert message={message} />}
          {error && <ErrorAlert message={message} />}
        </form>
      </div>
      <FormFooter />
    </div>
  );
};

export default InquiryForm;
