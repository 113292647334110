import React from "react";

const LauchCareer = () => {
  return (
    <div className="WWDContainer text-white flex-col justify-center items-center md:flex-row">
      <div className="item-1">
        <h1 className=" p-5 text-2xl md:text-3xl lg:text-5xl text-center md:text-left">
          Why launch
          <br />
          your CPA
          <br />
          career abroad?
        </h1>
      </div>

      <div className="item-2">
        <h2 className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl">
          <hr className="line-break" />
          International employment experience is critical to becoming a global
          CPA business professional and will give you an early advantage that
          will set you apart. You will develop the mindset and personal attributes
          that will allow you to see things differently, spot more
          opportunities, and solve problems others can’t. You will develop a wider
          and more diversified social network, see more ways to level-up in your
          career, and drive your trajectory higher.
        </h2>
      </div>
    </div>
  );
};

export default LauchCareer;
