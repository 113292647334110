import React from "react";
import "./Statements.scss";
const Statements = () => {
  return (
    <div className="statements">
      <section
        id="casestudies"
        className="casestudies grid grid-cols-1 md:grid-cols-2 gap-2"
      >
        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/handshake.jpg"
            />
          </article>
          <h2 className="content-text">
            Our #1 priority is connecting you with the right international CPA
            training opportunity.
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/5 star rating.jpg"
            />
          </article>
          <h2 className="content-text">
            Our knowledge, experience, and candidate-centric approach is your
            passport.
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/Qualification.jpg"
            />
          </article>
          <h2 className="content-text">
            Complete your CPA practical experience requirements while launching
            your global business career.
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/woman walking.jpg"
            />
          </article>
          <h2 className="content-text">
            Leverage your international experience to see a world of
            opportunities while you progress as a CPA.
          </h2>
        </div>
      </section>
    </div>
  );
};

export default Statements;
