import React from "react";
const Inquiry = () => {
  return (
    <div className="MContainer bg-white text-white flex-col justify-center items-center md:flex-row">
      <div style={{ backgroundColor: "white" }} className="item-1">
        <h1
          style={{ color: "black" }}
          className="p-5 text-xl sm:text-2xl md:text-3xl lg:text-5xl"
        >
          Inquire for yourself
        </h1>
      </div>

      <div style={{ backgroundColor: "white" }} className="item-2">
        <h2
          style={{ justifyContent: "left", color: "black" }}
          className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl"
        >
          <hr className="line-break" />
          If you are a Canadian CPA-bound senior student or recent grad (newly
          designated or in progress) and you want to inquire about international
          opportunities, feel free to get in touch with us confidentially.
          <a href="/inquiryform">
            <br />
            <br />
            <button className="w-full md:w-80 rounded">Inquire for yourself</button>
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Inquiry;
