import React from "react";

const Refer = () => {
  return (
    <div className="WWDContainer text-white flex-col justify-center items-center md:flex-row">
      <div className="item-1">
        <h1 className=" p-5 text-xl sm:text-2xl md:text-3xl lg:text-5xl">
          Refer a peer
        </h1>
      </div>
      <div className="item-2">
        <h2
          style={{ justifyContent: "left" }}
          className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl"
        >
          <hr className="line-break" />
          If you know a Canadian CPA-bound senior student or recent grad
          (newly designated or in progress) and you think they would benefit
          from having a conversation with us, feel free to make a referral here.
          <a href="/referform">
            <br />
            <br />
            <button className="w-full md:w-80 rounded" style={{ color: "black" }}>
            Refer a peer
            </button>
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Refer;
