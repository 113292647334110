import React from "react";

const WhyVM = () => {
  return (
    <div className="MContainer bg-white text-white flex-col justify-center items-center md:flex-row">
      <div style={{ backgroundColor: "white" }} className="item-1">
        <h1
          style={{ color: "black" }}
          className="p-5 text-xl sm:text-2xl md:text-3xl lg:text-5xl text-center md:text-left"
        >
          Why Vin Mitchell
        </h1>
      </div>

      <div style={{ backgroundColor: "white" }} className="item-2">
        <h2
          style={{ color: "black" }}
          className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl"
        >
          <hr className="line-break" />
          Let our experience guide you. We understand how CPA careers take flight internationally. And we understand
          the CPA accreditation process. We also understand it is important to you that you complete the CPA Practical
          Experience requirements as soon as you can and how your early work experiences can shape your career trajectory.
          We work exclusively with pre-certified CPA professionals and our staff has done so for nearly 15 years. It is
          our specialty.
        </h2>
        <br />
      </div>
    </div>
  );
};

export default WhyVM;
