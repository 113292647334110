import React from "react";
import "./HowItWorks.scss";

const HowItWorks = () => {
  return (
    <div className="container-2 grid-container HIWContainer">
      <div className="topdiv text-white justify-center items-center">
        <div className="item-1">
          <h1 className="p-5 text-2xl md:text-3xl lg:text-5xl">
            How it works
          </h1>
        </div>
        <div className="item-2">
          <h2 className="text-justify mx-12 text-lg sm:text-xl md:text-2xl lg:text-3xl">
            <hr className="line-break" />
            {/* <pre>1. Contact </pre> */}
            1. Contact <br />
            2. Connect <br />
            3. Assess <br />
            4. Refer <br />
            5. Flight <br />
          </h2>
        </div>
      </div>
      <div className="grid-item-3 bottomdiv">
        <ul>
          <li className="cards grid">
            <div className="card-content">
              <div className="card-content-1">
                <h2 style={{ color: "black", fontSize: "28px" }}>Contact</h2>
              </div>
              <div className="card-content-2">
                <img
                  src="assets/img/bald-head-with-magnifying-glass.png"
                  width="50px"
                  alt=""
                />
                <img src="assets/img/contact-form.png" width="50px" alt="" />
              </div>
              <div className="card-content-3">
                <p style={{ color: "black", fontSize: "24px" }}>
                  We contact you because you are already making a name for
                  yourself. Your LinkedIn profile identifies your experiences,
                  highlights your professional interests and shows your
                  potential.
                </p>
              </div>
            </div>
          </li>

          <li className="cards grid">
            <div className="card-content">
              <div className="card-content-1">
                <h2 style={{ color: "black", fontSize: "28px" }}>Connect</h2>
              </div>
              <div className="card-content-2">
                <img src="assets/img/chatbot.png" width="50px" alt="" />
                <img src="assets/img/relation.png" width="50px" alt="" />
              </div>
              <div className="card-content-3">
                <p style={{ color: "black", fontSize: "24px" }}>
                  We arrange for a virtual coffee meeting to share introductions
                  between us. We want to learn more about you, what drives you,
                  and what excites you about business. And you will want to know
                  about us too, what makes us experts, and how we can help.
                </p>
              </div>
            </div>
          </li>

          <li className="cards grid">
            <div className="card-content">
              <div className="card-content-1">
                <h2 style={{ color: "black", fontSize: "28px" }}>Assess</h2>
              </div>
              <div className="card-content-2">
                <img src="assets/img/microscope.png" width="50px" alt="" />
                <img src="assets/img/ruler.png" width="50px" alt="" />
              </div>
              <div className="card-content-3">
                <p style={{ color: "black", fontSize: "24px" }}>
                  While you reflect on launching your CPA business career
                  abroad, we consider your knowledge, skills, attributes and
                  goals in light of what employers are seeking. We want to make
                  sure we present you with the right opportunity. We will follow
                  up with you after to share all the details.
                </p>
              </div>
            </div>
          </li>

          <li className="cards grid">
            <div className="card-content">
              <div className="card-content-1">
                <h2 style={{ color: "black", fontSize: "28px" }}>Refer</h2>
              </div>
              <div className="card-content-2">
                <img src="assets/img/group.png" width="50px" alt="" />
                {/* <img src="assets/img/hand-shake.png" width="50px" alt="" /> */}
              </div>
              <div className="card-content-3">
                <p style={{ color: "black", fontSize: "24px" }}>
                  We introduce you to an employer by initiating a conversation
                  so that you can meet the people, assess the opportunity, and
                  participate in their interview processes. If we cannot make
                  an introduction based on our assessment, don’t worry. Our
                  networks are vast and we often make domestic referrals or
                  introductions as well!
                </p>
              </div>
            </div>
          </li>

          <li className="cards grid">
            <div className="card-content">
              <div className="card-content-1">
                <h2 style={{ color: "black", fontSize: "28px" }}>Flight</h2>
              </div>
              <div className="card-content-2">
                <img src="assets/img/contact-form.png" width="50px" alt="" />
                <img src="assets/img/plane.png" width="50px" alt="" />
              </div>
              <div className="card-content-3">
                <p style={{ color: "black", fontSize: "24px" }}>
                  Receive, review and accept your job offer.
                  Start full-time after completing your university
                  education and writing the CPA Common Final Exam.
                  If you have not already done so. Take flight,
                  you’ve launched your global CPA business career.
                </p>
              </div>
            </div>
          </li>
        </ul>
        <br />
      </div>
    </div>
  );
};

export default HowItWorks;
