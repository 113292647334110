import React from "react";
import "./FormNavBar.scss";

const FormNavBar = () => {
  const handleClick = () => {
    const navbarLinks = document.querySelector(".navbar-links");
    navbarLinks.classList.toggle("active");
  };

  return (
    <div className="formnavbarContainer">
      <div className="navbar">
        <a href="/">
          <img className="logo" src="assets/img/logo.jpg" alt="" />
        </a>
        <a onClick={handleClick} href="#a" className="toggle-button">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </a>
        <div className="navbar-links nohover">
          <ul>
            <li>
              <a href="/">BACK TO MAIN SITE</a>
            </li>
            <li>
              <a href="/inquiryform">INQUIRE FORM</a>
            </li>
            <li>
              <a href="/referform">REFER FORM</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormNavBar;
