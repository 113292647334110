import React from "react";
import "./StoryGrid.scss";
const StoryGrid = () => {
  return (
    <div className="statements">
      <section className="casestudies-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/confident girl.jpg"
              height="250"
            />
          </article>
          <h2 className="content-text">
            <b>Develop a clearer sense of self</b>
            <br />
            <br />
            <p>
              Researchers have found that living abroad leads to greater
              self-concept clarity, which can lead to psychological well-being,
              better job performance and clearer career decisions.
            </p>
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/creative mind.jpg"
              height="250"
            />
          </article>
          <h2 className="content-text">
            <b>
              Become a better problem-solver with entrepreneurial
              instincts
            </b>
            <br />
            <br />
            <p>
              Studies show that accepting ambiguity and diversity in the form of
              cultural differences help people understand that there are various
              solutions for problems and an array of goals worth achieving.
            </p>
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/hand heart filled.jpg"
              height="250"
            />
          </article>
          <h2 className="content-text">
            <b>Grow and show your global skills and mindset</b>

            <br />
            <br />
            <p>
              Working abroad in the right opportunity will enhance your cultural
              awareness and demonstrate your adaptiveness and initiative to
              future employers. You will be able to show that you have the global
              skills and values employers need because you’ve done it.
            </p>
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/girl with network.jpg"
              height="250"
            />
          </article>
          <h2 className="content-text">
            <b>
              Stretch your professional network well beyond Canadian borders
            </b>
            <br />
            <br />
            <p>
              Multinational employers with a global recruitment strategy hire
              the best talent. You will instantly grow your network to include
              your new colleagues and others you meet as a the result of letting
              your career take flight.
            </p>
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/girl with opportunity doors.jpg"
              height="250"
            />
          </article>
          <h2 className="content-text">
            <b>See more opportunities, open more doors sooner</b>
            <br />
            <br />
            <p>
              International business experience early on will help you level up
              early in your career. You will open doors in Europe, Asia, the USA
              and, of course, in Canada. The success of those that have done it -
              proves it.
            </p>
          </h2>
        </div>

        <div className="content">
          <article>
            <div className="content-overlay"></div>
            <img
              alt=""
              className="content-image"
              src="assets/img/girl getting promoted.jpg"
              height="250"
            />
          </article>
          <h2 className="content-text">
            <b>Boost your potential and your income for the future</b>
            <br />
            <br />
            <p>
              Research shows that immersing in foreign cultures is a key
              variable predicting future career progression. International
              experience is becoming more salient to executive recruiters
              seeking top-level CFOs. Internationally experienced professionals
              are not only more likely to win the CEO job, they also command
              more compensation.
            </p>
          </h2>
        </div>
      </section>
    </div>
  );
};

export default StoryGrid;
