import React from "react";
import "./FormFooter.scss";

const FormFooter = () => {
  return (
    <footer className="Formfooter">
      <p style={{ margin: "0" }}>
      Copyright Ⓒ Vin Mitchell. Ontario, Canada. All Rights Reserved.
      </p>
    </footer>
  );
};

export default FormFooter;
