import React, { useRef } from "react";
import Home from "./components/Home";
import HowItWorks from "./components/HowItWorks";
import Inquiry from "./components/Inquiry";
import LauchCareer from "./components/LauchCareer";
import Message from "./components/Message";
import NavBar from "./components/NavBar";
import PostGrad from "./components/PostGrad";
import Refer from "./components/Refer";
import StoryGrid from "./components/StoryGrid";
import Testimonials from "./components/Testimonials";
import WhatWeDo from "./components/WhatWeDo";
import WhyVM from "./components/WhyVM";
import Footer from "./components/Footer";
import Statements from "./components/Statements";

const HomePage = () => {
  const whatwedoref = useRef(null);
  const hotitworksref = useRef(null);
  const whyvmref = useRef(null);
  const formref = useRef(null);
  const handleLink = (e) => {
    console.log("e.target", e.target.name);
    e.preventDefault();
    switch (e.target.name) {
      case "whatwedo":
        whatwedoref.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "howitworks":
        hotitworksref.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "whyvm":
        whyvmref.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "form":
        formref.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <NavBar handleLink={handleLink} />
      <Home />
      <div ref={whatwedoref}>
        <WhatWeDo />
      </div>
      <Statements />
      <div ref={hotitworksref}>
        <HowItWorks />
      </div>
      <Message />
      <LauchCareer />
      <StoryGrid />
      <div ref={whyvmref}>
        <WhyVM />
      </div>
      <PostGrad />
      <div ref={formref}>
        <Inquiry />
      </div>
      <Refer />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default HomePage;
