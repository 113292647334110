import React, { useState } from "react";
import FormFooter from "./components/FormFooter";
import FormNavBar from "./components/FormNavBar";
import "./InquiryForm.scss";
import axios from "axios";
import {SuccessAlert, ErrorAlert} from "./components/alerts";

const ReferForm = () => {
  const [inputs, setInputs] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");


  const OnFormSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);
    try {
      const result = await axios.post("/api/v1/refer-form", inputs);
      if(result.data.success){
        setSuccess(true);
        setMessage(result.data.message);
        //setInputs({});
      }else{
        setError(true);
        setMessage(result.data.message);
      }
    } catch (error) {
      setError(true);
      if (error.response.data.error){
        setMessage(error.response.data.error);
        }else{
          setMessage(error.message)
        }

    }
  };
  const handleChange = (e) => {
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className="IQFormContainer">
      <FormNavBar />

      <form className="referForm" onSubmit={OnFormSubmit} style={{ marginBottom: "100px" }}>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_first_name">Your First Name:</label>
          <input
            type="text"
            name="first_name"
            maxLength="100"
            required
            id="id_first_name"
            onChange={handleChange}
            className="text-black"
            value={inputs.first_name}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_last_name">Your Last Name:</label>
          <input
            type="text"
            name="last_name"
            maxLength="100"
            required
            id="id_last_name"
            onChange={handleChange}
            className="text-black"
            value={inputs.last_name}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_email">Your Email Address:</label>
          <input
            type="email"
            name="email"
            maxLength="100"
            required
            id="id_email"
            onChange={handleChange}
            className="text-black"
            value={inputs.email}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_linkedin_profile">Your LinkedIn Profile Link:</label>
          <input
            type="text"
            name="linkedin_profile"
            maxLength="200"
            id="id_linkedin_profile"
            onChange={handleChange}
            className="text-black"
            value={inputs.linkedin_profile}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_peer_recognition">How do you know the Peer?</label>
          <input
            required
            type="text"
            name="peer_recognition"
            maxLength="500"
            id="id_peer_recognition"
            onChange={handleChange}
            className="text-black"
            value={inputs.peer_recognition}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_peer_first_name">Their First Name:</label>
          <input
            type="text"
            name="peer_first_name"
            maxLength="100"
            required
            id="id_peer_first_name"
            onChange={handleChange}
            className="text-black"
            value={inputs.peer_first_name}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_peer_last_name">Their Last Name:</label>
          <input
            type="text"
            name="peer_last_name"
            maxLength="100"
            required
            id="id_peer_last_name"
            onChange={handleChange}
            className="text-black"
            value={inputs.peer_last_name}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_peer_email">Their Email Address:</label>
          <input
            type="text"
            name="peer_email"
            maxLength="100"
            required
            id="id_peer_email"
            onChange={handleChange}
            className="text-black"
            value={inputs.peer_email}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_peer_linkedin_profile">
            Their LinkedIn Profile Link:
          </label>
          <input
            type="text"
            name="peer_linkedin_profile"
            maxLength="100"
            id="id_peer_linkedin_profile"
            onChange={handleChange}
            className="text-black"
            value={inputs.peer_linkedin_profile}
          />
        </div>
        <div className="flex flex-col md:flex-row m-2">
          <label htmlFor="id_message">Message:</label>
          <textarea
            name="message"
            cols="40"
            rows="10"
            maxLength="1000"
            required
            id="id_message"
            onChange={handleChange}
            className="text-black"
            value={inputs.message}
          ></textarea>
        </div>
        <input type="submit" value="Submit" className="submitbtn" />
        {success && <SuccessAlert message = {message}/>}
        {error && <ErrorAlert message = {message}/>}
      </form>
      <FormFooter />
    </div>
  );
};

export default ReferForm;
