import React from "react";
import "./Message.scss";

const Message = () => {
  return (
    <div className="MContainer bg-white text-white flex-col justify-center items-center md:flex-row">
      <div style={{ backgroundColor: "white" }} className="item-1">
        <h1
          style={{ color: "black" }}
          className="p-5 text-2xl md:text-3xl lg:text-5xl  text-center md:text-left"
        >
          No fees,
          <br />
          ever.
        </h1>
      </div>

      <div style={{ backgroundColor: "white" }} className="item-2">
        <h2
          style={{ color: "black" }}
          className="text-justify mx-12 text-lg sm:text-xl  md:text-2xl lg:text-3xl"
        >
          <hr className="line-break" />
          We do not accept fees from you. Nor are we a placement agency for you.
          We are your ally in your post-grad job search. We work under contract
          with international employers that want to hire the best talent. We
          win, when you win. You win when you have a role that launches your
          career as a global CPA business professional.
        </h2>
      </div>
    </div>
  );
};

export default Message;
