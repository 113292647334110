import React from "react";
import "./NavBar.scss";

const NavBar = ({ handleLink }) => {
  const handleClick = () => {
    const navbarLinks = document.querySelector(".navbar-links");
    navbarLinks.classList.toggle("active");
  };

  return (
    <div className="navbarContainer">
      <div className="navbar">
        <a href="/">
          <img className="logo" src="assets/img/logo.jpg" alt="" />
        </a>
        <span onClick={handleClick} className="toggle-button">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </span>
        <div className="navbar-links nohover">
          <ul>
            <li>
              <a href="#what-we-do" name="whatwedo" onClick={handleLink}>
                WHAT WE DO
              </a>
            </li>
            <li>
              <a href="#how-it-works" name="howitworks" onClick={handleLink}>
                HOW IT WORKS
              </a>
            </li>
            <li>
              <a href="#why-vin-mitchell" name="whyvm" onClick={handleLink}>
                WHY VIN MITCHELL?
              </a>
            </li>
            <li>
              <a href="#inquiry-form" name="form" onClick={handleLink}>
                INQUIRE / REFER
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
