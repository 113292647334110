import React from "react";
import "./Home.scss";

const Home = () => {
  const vid = "vin-mitchell-video"
  // const vid = window.screen.width > 768 ? "video=" : "videomobile";
  // useEffect(() => {
  //   Object.defineProperty(HTMLMediaElement.prototype, "playing", {
  //     get: function () {
  //       console.log("playing");
  //       return !!(
  //         this.currentTime > 0 &&
  //         !this.paused &&
  //         !this.ended &&
  //         this.readyState > 2
  //       );
  //     },
  //   });
  //   console.log("useEffect");
  //   const playfunction = () => {
  //     console.log("playfunction");
  //     const videoElement = document.getElementById("home_video");
  //     if (videoElement.playing) {
  //     } else {
  //       videoElement.play();
  //     }
  //   };
  //   document.body.addEventListener("click", playfunction);

  //   return () => {
  //     document.body.removeEventListener("click", playfunction);
  //   };
  // }, []);

  return (
    <div className="home-section" id="home">
      <div className="banner">
        <video loop autoPlay playsInline muted id="home_video">
          <source src={`assets/img/${vid}.mp4`} type="video/mp4" />
        </video>
        <div className="content">
          <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-white">
            Your CPA Global Business Career. Launched!
          </h1>
          <hr className="content-linebreak" />
          <p className="text-white">Let's Explore</p>
          <a href="#what-we-do">
            <button className="explore-btn" type="button">
              <p>
                <i className="arrow down"></i>
              </p>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
