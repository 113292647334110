const SuccessAlert = (props) => {
    const { message } = props;
    return (
        <div role="alert" className="m-8">
           
  <div className="bg-green-500 text-white font-bold rounded-t px-4 py-2">
        Success
  </div>
  <div className="border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
    <p>{message}</p>
  </div>
</div>
    );

}


export default SuccessAlert;