import "./App.css";
import HomePage from "./HomePage";
import { Route, Routes } from "react-router-dom";
import InquiryForm from "./InquiryForm";
import ReferForm from "./ReferForm";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/inquiryform" element={<InquiryForm />} />
      <Route path="/referform" element={<ReferForm />} />
    </Routes>
  );
}

export default App;
